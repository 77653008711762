<template>
    <div class="wrapper">
        <div class="container-fluid">
            <!-- Page-Title -->
            <div class="row">
                <div class="col-sm-12">
                    <div class="page-title-box">
                        <h2 class="page-title">Data Imunisasi</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-lg-3 col-sm-12">
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <div class="card overflow-hidden">
                                        <div class="card-heading bg-light">
                                            <input type="checkbox" id="filWilayah" v-model="isActive.wilayah"
                                                data-switch="default" @change="changeActive('wilayah', $event)"
                                                :checked="isActive.wilayah" />
                                            <label for="filWilayah" data-on-label="" data-off-label=""></label>
                                            <h3 class="card-title text-uppercase float-right">Filter Wilayah</h3>
                                            <p class="card-sub-title font-13 text-muted">Filter Ibu Hamil Per Wilayah
                                            </p>
                                        </div>
                                        <div class="card-body">
                                            <p class="m-t-0 header-title"><b>Kecamatan</b></p>
                                            <Select2 :disabled="!isActive.wilayah" v-model="filter.kecKode"
                                                :options="data.kecamatan" @select="selectKecamatan($event)" />
                                            <p class="header-title mt-2"><b>Desa</b></p>
                                            <Select2 :disabled="!isActive.wilayah" v-model="filter.kodeKab" :options="data.desa"
                                                @select="selectDesa($event)" />
                                            <p class="header-title mt-2"><b>Posyandu</b></p>
                                            <Select2 :disabled="!isActive.wilayah" v-model="filter.kdPosyandu"
                                                :options="data.posyandu" @select="selectPosyandu($event)" />
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <div class="card overflow-hidden">
                                        <div class="card-heading bg-light">
                                            <input type="checkbox" v-model="isActive.puskesmas" id="filPkm"
                                                data-switch="default" @change="changeActive('puskesmas', $event)"
                                                :checked="isActive.puskesmas" />
                                            <label for="filPkm" data-on-label="" data-off-label=""></label>
                                            <h3 class="card-title text-uppercase float-right">Filter Puskesmas</h3>
                                            <p class="card-sub-title font-13 text-muted">Filter Ibu Hamil Per Puskesmas
                                            </p>
                                        </div>
                                        <div class="card-body">
                                            <p class="header-title mt-2"><b>Puskesmas : {{ filter.pkmKode }}</b></p>
                                            <Select2 :disabled="!isActive.puskesmas" v-model="filter.pkmKode"
                                                :options="data.puskesmas" />
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <div class="card overflow-hidden">
                                        <div class="card-heading bg-light">
                                            <label for="filPkm" data-on-label="" data-off-label=""></label>
                                            <h3 class="card-title text-uppercase float-right">Filter Imunisasi</h3>
                                        </div>
                                        <div class="card-body">
                                            <p class="header-title mt-2"><b>Jenis Imunisasi</b></p>
                                            <Select2 v-model="filter.jenis" :options="data.jenis" />
                                            <div class="row mt-2 mb-2">
                                                <div class="col-xl-12 col-sm-12">
                                                    <label class="control-label">Tanggal Awal</label>
                                                    <Datepicker :format="formatStart" locale="id" v-model="dateStart"
                                                        cancelText="Batal" selectText="Pilih" />
                                                </div>
                                            </div>
                                            <div class="row mt-2 mb-2">
                                                <div class="col-xl-12 col-sm-12">
                                                    <label class="control-label">Tanggal Akhir</label>
                                                    <Datepicker :format="formatEnd" locale="id" v-model="dateEnd"
                                                        cancelText="Batal" selectText="Pilih" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                            <!--end row -->
                            <div class="row">
                                <div class="col-xl-12 col-sm-12">
                                    <button @click="showData()"
                                        class="btn btn-custom btn-block waves-effect waves-light w-lg m-b-5"
                                        :disabled="is_loading"><span
                                            :class="{ 'spinner-grow spinner-grow-sm mr-2': is_loading }" role="status"
                                            aria-hidden="true"></span>{{ is_loading ? "Loading..." : "Tampilkan Data"
                                            }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9 col-sm-12">
                            <div class="card overflow-hidden">
                                <div class="card-heading bg-light">
                                    <h3 class="card-title text-uppercase">Data Imunisasi</h3>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="tb-imunisasi"
                                            class="table table-striped table-bordered dt-responsive nowrap"
                                            style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                            <thead>
                                                <tr>
                                                    <th>Tanggal</th>
                                                    <th>No Index</th>
                                                    <th>Nama Bayi</th>
                                                    <th>Tgl Lahir</th>
                                                    <th>J.K</th>
                                                    <th>Posyandu</th>
                                                    <th>Dusun</th>
                                                    <th>Desa</th>
                                                </tr>
                                            </thead>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End container-fluid -->
            <Footer />
        </div>
    </div>
</template>

<script>
import Footer from '@/views/layouts/FooterComponent.vue';
import axios from 'axios';
import Select2 from 'vue3-select2-component';
import { useToast } from "vue-toastification";
import { ref, onMounted } from 'vue';

export default {
    name: 'Home',
    components: {
        Footer,
        Select2
    },
    setup() {
        const dateStart = ref(new Date());
        const dateEnd = ref(new Date());
        const toast = useToast();

        const formatStart = (dateStart) => {
            return `${dateStart.getDate()}/${dateStart.getMonth() + 1}/${dateStart.getFullYear()}`;
        }

        const formatEnd = (dateEnd) => {
            return `${dateEnd.getDate()}/${dateEnd.getMonth() + 1}/${dateEnd.getFullYear()}`;
        }

        return {
            dateStart,
            dateEnd,
            formatStart,
            formatEnd,
            toast
        }
    },
    data() {
        return {
            imunisasi: [],
            is_loading: false,
            isActive: {
                puskesmas: false,
                wilayah: true,
                periode: false
            },
            filter: {
                pkmKode: '',
                kecKode: '',
                kodeKab: 'all',
                kdPosyandu: 'all',
                dateStart: '',
                dateEnd: '',
                jenis: 'BCG'
            },
            data: {
                puskesmas: [],
                kecamatan: [],
                desa: [],
                posyandu: [{ id: 'all', text: '- Semua Posyandu -' }],
                jenis: [
                    { id: "BCG", text: "BCG" },
                    { id: "BoosterPentavalen", text: "Booster Pentavalen" },
                    { id: "BoosterCampak", text: "Booster Campak" },
                    { id: "Campak", text: "Campak" },
                    { id: "DPT_1", text: "DPT 1" },
                    { id: "DPT_2", text: "DPT 2" },
                    { id: "DPT_3", text: "DPT 3" },
                    { id: "Hepatitis_0", text: "Hepatitis 0" },
                    { id: "IPV", text: "IPV" },
                    { id: "Polio_1", text: "Polio 1" },
                    { id: "Polio_2", text: "Polio 2" },
                    { id: "Polio_3", text: "Polio 3" },
                    { id: "Polio_4", text: "Polio 4" }
                ]
            }
        };
    },
    methods: {
        async getPuskesmas() {
            await axios.get(`dasar/puskesmas`)
                .then(response => {
                    this.data.puskesmas = response.data.content.puskesmas.map(pkm => (
                        { id: pkm.Kode, text: pkm.Puskesmas.toUpperCase() }
                    ));
                    this.filter.pkmKode = this.data.puskesmas[0].id;
                })
                .catch(e => {
                    this.$toast.error(e.response.data.errors.message.description);
                })
        },
        async getKecamatan() {
            await axios.get(`dasar/kecamatan`)
                .then(response => {
                    this.data.kecamatan = response.data.content.kecamatan.map(kec => (
                        { id: kec.Kode_Kecamatan, text: kec.Kecamatan.toUpperCase() }
                    ));
                    this.filter.kecKode = this.data.kecamatan[0].id;
                    this.getDesa();
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                });
        },
        async getDesa() {
            await axios.get(`dasar/desa/` + this.filter.kecKode)
                .then(response => {
                    var desa = response.data.content.desa.map(item => (
                        { id: item.KodeKab, text: item.Desa.toUpperCase() }
                    ));

                    var defDesa = [{ id: 'all', text: '- Semua -' }];
                    this.data.desa = [...defDesa, ...desa];
                    this.filter.kodeKab = this.data.desa[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async getPosyandu() {
            await axios.get(`dasar/posyandu/` + this.filter.kodeKab)
                .then(response => {
                    var posyandu = response.data.content.posyandu.map(item => (
                        { id: item.Kode_Posyandu, text: item.Posyandu.toUpperCase() }
                    ));

                    var defPosyandu = [{ id: 'all', text: '- Semua Posyandu -' }];
                    this.data.posyandu = [...defPosyandu, ...posyandu];
                    this.filter.kdPosyandu = this.data.posyandu[0].id;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async showData() {
            this.is_loading = true;
            await axios
                .get('balita/imunisasi', {
                    params: {
                        jenis: this.filter.jenis,
                        kdKecamatan: (this.isActive.wilayah) ? this.filter.kecKode : '',
                        kdDesa: (this.isActive.wilayah && this.filter.kodeKab !== 'all') ? this.filter.kodeKab : '',
                        kdPosyandu: (this.isActive.wilayah && this.filter.kdPosyandu !== 'all') ? this.filter.kdPosyandu : '',
                        kdPkm: (this.isActive.puskesmas) ? this.filter.pkmKode : '',
                        start: this.dateStart,
                        end: this.dateEnd
                    }
                })
                .then(response => {
                    this.is_loading = false;
                    this.imunisasi = response.data.content.imunisasi;
                })
                .catch(e => {
                    this.is_loading = false;
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        selectKecamatan(event) {
            if (event.id !== '') {
                this.getDesa();
            }
        },
        changeActive(current, event) {
            if (current == 'wilayah') {
                this.isActive.puskesmas = false;
                this.isActive.wilayah = true;
            } else {
                this.isActive.wilayah = false;
                this.isActive.puskesmas = true;
            }
        },
        selectDesa(event) {
            if (event.id !== '') {
                this.getPosyandu();
            }
        },
    },
    watch: {
        imunisasi() {
            $('#tb-imunisasi').DataTable().destroy();
            $('#tb-imunisasi').DataTable({
                processing: true,
                responsive: true,
                destroy: true,
                orders: [],
                data: this.imunisasi,
                lengthMenu: [18, 50, 'All'],
                columns: [
                    { data: "Tanggal" },
                    { data: "No_Index_Balita_Dinas" },
                    { data: "Nama_Bayi" },
                    { data: "Tanggal_Lahir" },
                    { data: "Jenis_Kelamin" },
                    { data: "Posyandu" },
                    { data: "Dusun" },
                    { data: "Desa" }
                ],
                language: {
                    "lengthMenu": "Menampilkan _MENU_ data per halaman",
                    "zeroRecords": "Belum ada data untuk saat ini",
                    "info": "Menampilkan halaman _PAGE_ dari _PAGES_",
                    "infoEmpty": "Belum ada data",
                    "infoFiltered": "(Filter dari _MAX_ total data)",
                    "paginate": {
                        "next": "Selanjutnya",
                        "previous": "Sebelumnya"
                    },
                    "search": "Cari data : "
                },
            });
        },
        $route(to, from) {
            $('#tb-imunisasi').DataTable().destroy();
        }
    },
    created() {
        this.getPuskesmas();
        this.getKecamatan();
        this.$nextTick(function () {
            $('#tb-imunisasi').DataTable();
        })
    }
};
</script>

<style lang="css" scoped>
</style>
